import React, { useState, useEffect } from "react";
import "../../../app.css";
import { Button } from "reactstrap";
import eventBanners from "assets/img/theme/EventDetailsBanners.jpeg";
import { FaClock, FaUser, FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
import MemberShipForm from "./MemberShipForm";
import NewCALogo from "assets/img/theme/NewCA-Logo.jpg"
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Badge,
  Row,
  Col,
  Container,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import Loading from "components/LoadingComponents/Loading.js";
import FormExample from "../UpcomingEvents/MemberRegForm";

import MemeberIdForm from "./MemeberIdForm";
import FetchApiMethod from "Helpers/FetchApiMethod";
import { swalfirefunctionality } from "services/alertServices";
import { imageURL } from "components/URLs/URL";

// function formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = date.getDate().toString().padStart(2, '0');
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
// }

function formatDate(dateString) {
    const date = new Date(dateString);
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate(); // Get number of days in the month
    let day = date.getDate();
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthAbbreviations[date.getMonth()];
    const year = date.getFullYear();
    
    // Adjust day if it's the last day of the month
    if (day === daysInMonth) {
        // day = '31';
    }

    return `${day}-${month}-${year}`;
}

// function formatDate(dateString) {
//   const date = new Date(dateString);
//   const daysInMonth = new Date(
//     date.getFullYear(),
//     date.getMonth() + 1,
//     0
//   ).getDate(); // Get number of days in the month
//   let day = date.getDate();
//   const monthAbbreviations = [
//     "Jan",
//     "Feb",
//     "Mar",
//     "Apr",
//     "May",
//     "Jun",
//     "Jul",
//     "Aug",
//     "Sep",
//     "Oct",
//     "Nov",
//     "Dec",
//   ];
//   const month = monthAbbreviations[date.getMonth()];
//   const year = date.getFullYear();

//   // Adjust day if it's the last day of the month
//   if (day === daysInMonth) {
//     day = "31";
//   }

//   return `${day}-${month}-${year}`;
// }

const EventDetails = () => {
  const [event, setEvent] = useState(null);
  const [show, setShow] = useState(false);
  const [memberStatus, setMemberStatus] = useState(false);
  const [toggleCheck, setToggleCheck] = useState(false);
  const [memberData, setMemberData] = useState(null);
  // const [annual_yn,setAnnual_yn]=useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [paymentStats, setPaymentStats] = useState(false);
  const [payUserDetails, setPayUserDetails] = useState([]);
  const [eventID, setEventID] = useState('')
  const [loader, setLoader] = useState(false);

  const {membership_no,name,organisation_address,organisation_gstin,organisation_name,phone,pincode,state,email,city,annual_yn}=memberData??[];


  const location = useLocation();
  const url_event_id = new URLSearchParams(location.search).get("id");

  const fetchEvent = async (url_event_id) => {
    // const apihandler = `https://icaigbnapi.proeffico.com/api/event/getEvents?postman=Y&event_id=${url_event_id}`;
    // const settings = {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer 2d395b0b-0abc-4c1b-89b4-a0ca45038b0d",
    //   },
    // };


    try {
      const apihandlerres = await FetchApiMethod(`event/getEvents?postman=Y&event_id=${url_event_id}`, "POST");
      // const respodata = await apihandlerres.json();
      // setEvent(respodata.data[0]);
      setEvent(apihandlerres.data[0] || []);
    } catch (error) {
      console.error("Error fetching event details:", error);
    }
  };

  useEffect(() => {
    fetchEvent(url_event_id);
  }, [url_event_id]);

  if (!event) {
    return <Loading />;
  }


  const handlemodelview = () => {
    show == false ? setShow(true) : setShow(false);
  };

  const handleMemberIdSubmit = (data) => {
    data.postman = "Y";
    const getMemberShipData = FetchApiMethod(
      "membership/getMemberRegistration",
      data,
      "POST"
    );
    getMemberShipData.then((res) => {
      try {
        if (res.status && res.message == "success") {
          setMemberData(res.data[0]);
          setShow(true);
          // setAnnual_yn(res.data[0].annual_yn??"Y");
          swalfirefunctionality(`${res.data[0].annual_yn=="Y"?"Great! You are a annual member":res.data[0].annual_yn=="N"?"You are not a annual member" :""}`, res.data[0].annual_yn=="N"?"": `` ,res.data[0].annual_yn=="N"?"warning": "success");
        } else {
          swalfirefunctionality("error", `${res.message}`, "error");
          setMemberData(null);
          setShow(false);
          // setAnnual_yn(null);
        }
      } catch (error) {
        swalfirefunctionality("error", `${error.message}`, "error");
      }
    });
  };

  const handleAddEventRegistrSubmit = (data) => {
    console.log('data',data)
    let requestData = {};
    let memberPhone=data.phone.includes('XXX')?phone:data.phone;
    let memberEmail=data.email.includes('xxx')?email:data.email;
    data.contact_no=memberPhone;
    data.email_id=memberEmail;
    requestData.type = annual_yn==="Y"?"Annual Member":"Non-Annual Member";
      if(!toggleCheck){
        requestData.contact_no = memberPhone;
        requestData.organization = data.organisation?data.organisation:organisation_name;
        requestData.email_id = memberEmail;
        requestData.name = data.name?data.name:name;
        requestData.membership_number = data.membership_no? data.membership_no:membership_no;
      }else{
        requestData.contact_no = phone;
        requestData.organization = organisation_name;
        requestData.email_id = email;
        requestData.name = name;
        requestData.membership_number = membership_no;
      }
      requestData.event_id=url_event_id;
      requestData.select_payment_category= "Online";
      requestData.country="India"
      console.log(requestData,'requestData')
      
    const addEventRegistrationApiResult = FetchApiMethod(
      "addEventRegistration",
      requestData,
      "POST"
    );
    addEventRegistrationApiResult.then((res) => {
      try {
        if (res.message=="Event registration created successfully") {
          swalfirefunctionality("Good job",res.message,"success");
          if(annual_yn != 'Y' || res.data.type=="Non-Annual Member"){
            setPaymentStats(true);
            setPayUserDetails(data);
            setEventID(url_event_id);
          }
          if(event.member_fee==0 && res.data.type=="Annual Member"){
            setIsChecked(false);
            setShow(false)
          }else if(event.non_member_fee==0 && res.data.type=="Non-Annual Member"){
            setIsChecked(false);
            setShow(false)
          }
        }
       else if(res.message=="You are already registered but payment is pending" && res.payment_yn==null){
          swalfirefunctionality("",res.message,"warning");
          if(annual_yn != 'Y'){
            setPaymentStats(true);
            setPayUserDetails(data);
            setEventID(url_event_id);
          }
          if(event.member_fee==0 && res.data.type=="Annual Member"){
            setIsChecked(false);
            setShow(false)
          }else if(event.non_member_fee==0 && res.data.type=="Non-Annual Member"){
            setIsChecked(false);
            setShow(false)
          }          
        }
        else{
            swalfirefunctionality("",res.message,"warning");
            setShow(false);
            setIsChecked(false)
        }
      } catch (error) {
        swalfirefunctionality("error",error,"error");
      }
    });
  };

  
  
  const togglBtn = () => {
    setMemberStatus(true) 
    setToggleCheck(true) 
    setShow(false);
    setIsChecked(false);    
  };
  const handleRadioFlag = () => {
   setIsChecked(true);
    setMemberStatus(false)
     setToggleCheck(false);
     setShow(false);
     setPaymentStats(false);
     setMemberData(null);
    
  };

const handleInputChange=(event)=>{
event.target.value==""?setShow(false):setShow(false);
}


  return (
    <div>
      <div style={{ position: "relative" }}>
        {/* Image */}
        <Card>
          <Row className="justify-content-center">
            <img alt="Event Details Images" className="imgEventHeight" src={`${imageURL}/public/img/${event.images}`} onError={(e) => e.target.src = NewCALogo} top style={{ height: '35rem', width: '100%' }}/>
          </Row>
          {/* <Row className="justify-content-center">
            <img alt="Event Details Images" className="imgEventHeight" src={`https://icaigbnapi.proeffico.com/public/img/${event.images}`} top style={{ height: '40rem', width: '100%' }}/>
          </Row> */}
          <CardBody
            style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
            <br /> <br /> <br /> <br /> <br /> <br />
            <div className="text-center mt-6">
            </div>
          </CardBody>
        </Card>
      </div>

      {/* <section className=""> */}
      <div className="container-fluid">
        <Row
          className="bg-secondary align-items-center justify-content-center "
          // style={{ marginTop: "-3rem" }}
        >
          <Col
            lg="2"
            className="bg-light p-2 border-right border-white"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaClock size="2em" color="black" />
            <p style={{ margin: "0 5px", fontWeight: 500 }}>
              {event.days_left}
            </p>
            <h6 style={{ margin: "0" }}> Days To Go</h6>
          </Col>

          <Col
            lg="2"
            className="bg-light p-2  border-right border-white"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaCalendarAlt size="2em" color="black" />
            <p style={{ margin: "0 5px", fontWeight: 500 }}>
              {formatDate(event.start_date)}
            </p>
            {/* <h6 style={{ margin: '0' }}> Duration: 2 days</h6> */}
          </Col>

          <Col
            lg="6"
            className="bg-light p-2  border-right border-white"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FaMapMarkerAlt size="2em" color="black" />
            <p style={{ margin: "0 5px", fontWeight: 500 }}>{event.address}</p>
            {/* <h6 style={{ margin: '0' }}> Gautam Budh Nagar </h6> */}
          </Col>
          {/* <Col lg="2" className="bg-light p-2 " style={{ display: 'flex', alignItems: 'center' }}>
              <FaUser size="2em" color="black" />
              <h6 style={{ margin: '0' }}> 23 sheet left</h6>
            </Col> */}
        </Row>

        <Row className="justify-content-center bg-white">
          <Col lg="8" className="bg-secondary p-2">
            {/* <Row className="justify-content-center"> */}
            <h2 className="text-center mb-4 allMainHeading">{event.event_name}</h2>
            {/* </Row> */}
            <h5 className="text-center" style={{ color: "#05007E" }}>
              Event Date: {formatDate(event.end_date)}
            </h5>
            <p className="text-center">{event.description}</p>
          </Col>
        </Row>
      </div>

      <Card className="bg-white pt-0 border-0">
        <Row className="justify-content-center mx-3">
          <table className="table table-bordered border-dark">
            <thead>
              <tr>
                <th>Topic</th>
                <th>Speakers</th>
              </tr>
            </thead>
            <tbody>
              {event.events_topics.map((topic, index) => (
                <tr key={index}>
                  <td>{topic.topic_names}</td>
                  <td>{topic.speaker_names}</td>
                </tr>
              ))}

              <tr>
                <td colSpan="2"><b>Fees: Annual Member {event.member_fee} </b>(Excluding GST)</td>
              </tr>
              <tr>
                <td colSpan="2"><b>Fees : Non-Annual Member {event.non_member_fee} </b>(Excluding GST)</td>
              </tr>
            </tbody>
          </table>
        </Row>

        {event.event_status!="Disable" && <MemeberIdForm
          handleSubmit={handleMemberIdSubmit}
          handleAddEventRegistrSubmit={handleAddEventRegistrSubmit}
          handleToggleBtn={togglBtn}
          handleRadioFlag={handleRadioFlag}
          toggleCheck={toggleCheck}
          memberStatus={memberStatus}
          showForm={show}
          memberData={memberData}
          memberFee={event.member_fee}
          nonMemberFee={event.non_member_fee}
          is_Annual_yn={memberStatus?annual_yn:"N"}
          event_id={url_event_id}
          isChecked={isChecked}
          handleInputChange={handleInputChange}
          paymentStats={paymentStats}
          payUserDetails={payUserDetails}
          eventID={eventID}
          setMemberData={setMemberData}
          price={event.non_member_fee}
          setLoader={setLoader}
          loader={loader}
        />}
        <br />
      </Card>
    </div>
  );
};

export default EventDetails;
